// 定义首页资讯类型的枚举
export enum NewsType {
  /** 官方资讯 */
  OFFICIAL_NEWS = 'official_news',
  /** 烘焙资讯 */
  BAKING_NEWS = 'baking_news',
  /** 产品资讯 */
  PRODUCT_NEWS = 'product_news',
  /** 优惠资讯 */
  DISCOUNT_NEWS = 'discount_news',
}

// 定义 NewsType 对象
export const NewsIdMap: Record<NewsType, number> = {
  [NewsType.OFFICIAL_NEWS]: 2,
  [NewsType.BAKING_NEWS]: 3,
  [NewsType.PRODUCT_NEWS]: 4,
  [NewsType.DISCOUNT_NEWS]: 5,
}

/** 特价商品 ｜ 热卖商品  */
export enum SpecialType {
  /** 特价商品 */
  SPECIAL_PRODUCT = 'special_product',
  /** 热卖商品 */
  HOT_PRODUCT = 'hot_product',
}
export const SpecialTypeMap: Record<SpecialType, number> = {
  [SpecialType.SPECIAL_PRODUCT]: 0,
  [SpecialType.HOT_PRODUCT]: 1,
}
